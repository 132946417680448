import { type ActionFunctionArgs, type SerializeFrom, json } from '@remix-run/node';
import { namedAction } from 'remix-utils/named-action';
import { z } from 'zod';
import { ListingAvailabilityRequestVerifyEmail } from '~/components/emails/request-listing-availability.email.server.tsx';

const requestSchema = z.object({
  email: z
    .string()
    .email()
    .transform((email) => email.toLowerCase()),
});

const verifySchema = z.object({
  code: z.string().min(6).max(6),
  email: z.string().email(),
  address: z.string(),
  from: z.coerce.date(),
  to: z.coerce.date(),
});

export const verificationType = 'listings-availability';

export async function requestListingsAvailability(data: z.infer<typeof requestSchema>) {
  const resp = await fetch('/resources/request-listings-availability?/request', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return (await resp.json()) as SerializeFrom<typeof action>;
}

export async function verifyListingsAvailability(data: z.infer<typeof verifySchema>) {
  const resp = await fetch('/resources/request-listings-availability?/verify', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return (await resp.json()) as SerializeFrom<typeof action>;
}

export async function action({ request, context }: ActionFunctionArgs) {
  const data = await request.json();

  return namedAction(request, {
    async request() {
      const requestResult = requestSchema.safeParse(data);

      if (!requestResult.success) {
        return json(
          {
            status: 'error' as const,
            error: 'Invalid email',
          },
          { status: 400 },
        );
      }

      const { email } = requestResult.data;

      const verification = await context.verifications.createVerification({
        type: verificationType,
        target: email,
      });

      const response = await context.email.sendEmail({
        to: email,
        subject: 'Verify your email on hostU',
        react: <ListingAvailabilityRequestVerifyEmail otp={verification.code} />,
      });

      if (context.env.NODE_ENV === 'development') {
        context.logger.info({
          otpCode: verification.code,
        });
      }

      if (response.status === 'success') {
        return json({ status: 'verification-sent' as const });
      }
      return json(
        {
          status: 'error' as const,
          error: 'Failed to send email',
        } as const,
        { status: 500 },
      );
    },
    async verify() {
      const verifyResult = verifySchema.safeParse(data);

      if (!verifyResult.success) {
        return json(
          {
            status: 'error' as const,
            error: 'Invalid request',
          },
          { status: 400 },
        );
      }

      const { code, email, address, from, to } = verifyResult.data;

      const verification = await context.verifications.verifyCode({
        type: verificationType,
        target: email,
        code,
      });

      if (verification.status === 'error') {
        return json(
          {
            status: 'error' as const,
            error: verification.message,
          },
          { status: 400 },
        );
      }

      await context.verifications.deleteVerifications({
        type: verificationType,
        target: email,
      });

      await context.db.listingAvailabilityRequest.upsert({
        where: {
          email: email,
        },
        update: {
          address: address,
          from: from,
          to: to,
        },
        create: {
          email: email,
          address: address,
          from: from,
          to: to,
        },
      });

      return json({ status: 'success' as const });
    },
  });
}
